import React from 'react'
import PropTypes from '../prop-types'
import Helmet from 'react-helmet'
import Header from './header'
import Footer from './footer'
import Alert from 'organisms/alert'
import '../scss/main.scss'

const Layout = ({ children }) => {
  const host = typeof window !== 'undefined' ? window.location.hostname : ''
  const langSubdomain = host.split('.')[0]
  const canonicalUrl = `https://${host}/`
  console.log('canonicalUrl', canonicalUrl)

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Alert />
      <main className="l-main">
        <Header />
        <div className="l-content">{children}</div>
        <Footer />
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.func,
}

export default Layout
